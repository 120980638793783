export default {
  install: (app) => {
    app.config.globalProperties.$auth = {
      setToken(data) {
        localStorage.setItem("token", data.access_token);
      },
      active() {
        return localStorage.getItem("token") || false;
      },
      logout() {
        localStorage.removeItem("token");
        location.replace("/");
      },
    };
  },
};
