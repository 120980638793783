<template>
  <header id="page-topbar">
    <div class="navbar-header">
      <div class="d-flex">
        <button
          class="btn btn-sm px-3 font-size-26 header-item vertical-menu-btn"
          @click="handleSideBar"
        >
          <i class="uil uil-bars"></i>
        </button>
        <div
          class="dropdown d-none d-lg-block ms-2"
          v-if="!user.company_id && !user.role_id"
        >
          <button
            type="button"
            class="btn header-item"
            data-bs-toggle="dropdown"
            aria-haspopup="false"
            aria-expanded="false"
          >
            <i class="uil uil-building fs-5 me-1"></i>
            <span v-if="$store.state.selectedCompany">{{
              $store.state.selectedCompany.name
            }}</span>
            <span v-else>{{ $trans("All companies") }}</span>
            <i class="uil uil-angle-down"></i>
          </button>
          <div class="dropdown-menu">
            <a
              v-if="$store.state.selectedCompany"
              class="dropdown-item"
              @click.prevent="setCompany(null)"
              href="javascript:void(0)"
              ><i class="uil uil-building fs-5 me-1"></i>All</a
            >
            <a
              class="dropdown-item"
              href="javascript:void(0)"
              v-for="company in companies"
              :key="company.id"
              @click.prevent="setCompany(company)"
              ><i class="uil uil-building fs-5 me-1"></i>{{ company.name }}</a
            >
          </div>
        </div>
      </div>

      <div class="d-flex">
        <div class="dropdown d-inline-block">
          <button
            @click="SwitchMode"
            class="btn header-item noti-icon right-bar-toggle uil fs-5 me-1 switch"
            :class="(mode, ActiveClass)"
          ></button>
        </div>
        <button
          @click="SwitchLayout"
          class="btn header-item noti-icon right-bar-toggle uil fs-5 me-1 switch"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            class="bi bi-front"
            viewBox="0 0 16 16"
          >
            <path
              d="M0 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v2h2a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2v-2H2a2 2 0 0 1-2-2V2zm5 10v2a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V6a1 1 0 0 0-1-1h-2v5a2 2 0 0 1-2 2H5z"
            />
          </svg>
        </button>
        <div class="dropdown d-inline-block">
          <button
            type="button"
            class="btn header-item noti-icon"
            id="page-header-notifications-dropdown"
            data-bs-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="true"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="25"
              height="25"
              fill="currentColor"
              class="bi bi-bell"
              viewBox="0 0 16 16"
            >
              <path
                d="M8 16a2 2 0 0 0 2-2H6a2 2 0 0 0 2 2zM8 1.918l-.797.161A4.002 4.002 0 0 0 4 6c0 .628-.134 2.197-.459 3.742-.16.767-.376 1.566-.663 2.258h10.244c-.287-.692-.502-1.49-.663-2.258C12.134 8.197 12 6.628 12 6a4.002 4.002 0 0 0-3.203-3.92L8 1.917zM14.22 12c.223.447.481.801.78 1H1c.299-.199.557-.553.78-1C2.68 10.2 3 6.88 3 6c0-2.42 1.72-4.44 4.005-4.901a1 1 0 1 1 1.99 0A5.002 5.002 0 0 1 13 6c0 .88.32 4.2 1.22 6z"
              />
            </svg>
            <span
              class="noti-dot bg-danger spinner-grow"
              v-if="hasNotifications"
            ></span>
          </button>
          <div
            class="dropdown-menu dropdown-menu-lg dropdown-menu-end p-0"
            aria-labelledby="page-header-notifications-dropdown"
            data-popper-placement="bottom-end"
          >
            <div class="p-3">
              <div class="row align-items-center">
                <div class="col">
                  <h5 class="m-0 font-size-15">
                    {{ $trans("Notifications") }}
                  </h5>
                </div>
                <div class="col-auto">
                  <a
                    href="javascript:void(0);"
                    class="small"
                    @click="readNotifications"
                  >
                    {{ $trans("Mark all as read") }}</a
                  >
                </div>
              </div>
            </div>
            <div
              data-simplebar="init"
              style="max-height: 250px; min-height: auto; padding-bottom: 250px"
            >
              <div class="simplebar-wrapper" style="margin: 0px">
                <div class="simplebar-height-auto-observer-wrapper">
                  <div class="simplebar-height-auto-observer"></div>
                </div>

                <div class="simplebar-mask">
                  <div
                    class="simplebar-offset"
                    style="right: 0px; bottom: 0px; height: auto"
                  >
                    <div
                      class="simplebar-content-wrapper"
                      style="
                        height: auto;
                        padding-right: 0px;
                        padding-bottom: 0px;
                        overflow: scroll;
                      "
                    >
                      <div class="simplebar-content" style="">
                        <h6 class="dropdown-header bg-light">New</h6>
                        <RouterLink
                          :to="`/reports/${item.data.notification.link}/${item.data.notification.slug}`"
                          class="text-reset notification-item"
                          v-for="(item, i) in notifications"
                          :key="'notification' + i"
                          @click="readSingleNoti(item.data.id)"
                        >
                          <div class="d-flex border-bottom align-items-start">
                            <div class="flex-shrink-0">
                              <div class="avatar-sm me-3">
                                <span
                                  class="avatar-title bg-soft-success text-success rounded-circle font-size-20"
                                >
                                  <i class="uil-message"></i>
                                </span>
                              </div>
                            </div>
                            <div class="flex-grow-1">
                              <h6 class="mb-1">
                                {{ i + 1 }} - {{ item.data.notification.type }}
                              </h6>
                              <div class="text-muted">
                                <p
                                  class="mb-1 font-size-13"
                                  v-html="item.data.notification.message"
                                ></p>
                                <p
                                  class="mb-0 font-size-10 text-uppercase fw-bold"
                                >
                                  <i class="mdi mdi-clock-outline"></i>
                                  {{
                                    $helper.timeDifference(
                                      new Date(item.created_at)
                                    )
                                  }}
                                </p>
                              </div>
                            </div>
                          </div>
                        </RouterLink>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="simplebar-placeholder"
                  style="width: 0px; height: 56px"
                ></div>
              </div>
            </div>
            <div class="p-2 border-top d-grid">
              <a
                class="btn btn-sm btn-link font-size-14 btn-block text-center"
                href="javascript:void(0)"
              >
                <i class="uil-arrow-circle-right me-1"></i>
                <span>View More..</span>
              </a>
            </div>
          </div>
        </div>
        <div class="dropdown d-inline-block language-switch">
          <button
            type="button"
            class="btn header-item show rounded-circle header-profile-user"
            style="border-color: #ddd; margin-top: 17px"
            :style="{ 'background-image': 'url(/img/' + country.flag + ')' }"
            data-bs-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="true"
          ></button>
          <div
            class="dropdown-menu dropdown-menu-end"
            style="
              position: absolute;
              inset: 0px 0px auto auto;
              margin: 0px;
              transform: translate3d(0.5px, 72px, 0px);
            "
            data-popper-placement="bottom-end"
          >
            <a
              v-for="(item, i) in languages"
              :key="'lang' + i"
              href="javascript:void(0);"
              class="dropdown-item notify-item language"
              @click.prevent="setLanguage(item)"
            >
              <img
                :src="`/img/${item.flag}`"
                alt="user-image"
                class="me-2 rounded-circle"
                height="20"
                width="20"
              />
              <span class="align-middle">{{ item.label }}</span>
            </a>
          </div>
        </div>
        <div class="dropdown d-inline-block">
          <button
            type="button"
            class="btn header-item user text-start d-flex align-items-center"
            id="page-header-user-dropdown"
            data-bs-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            <img
              class="rounded-circle header-profile-user"
              src="/img/avatar.png"
              alt="Header Avatar"
            />
            <span class="ms-2 d-none d-sm-block user-item-desc">
              <span class="user-name">{{ user.name }}</span>
              <span class="user-sub-title">{{
                user.role && user.role.name
              }}</span>
            </span>
          </button>
          <div class="dropdown-menu dropdown-menu-end pt-0" style="">
            <div class="p-3 bg-primary border-bottom">
              <h6 class="mb-0 text-white">{{ user.name }}</h6>
              <p class="mb-0 font-size-11 text-white-50 fw-semibold">
                {{ user.email }}
              </p>
            </div>
            <RouterLink to="/profile" class="text-capitalize dropdown-item">
              <i
                class="uil uil-setting fs-5 align-middle me-2"
                data-v-d1a7e442=""
              ></i>
              <span class="align-middle">{{ $trans("My Profile") }}</span>
            </RouterLink>
            <a
              class="dropdown-item"
              href="javascript:void(0)"
              @click.prevent="handleLogout"
              ><i
                class="uil uil-sign-out-alt fs-5 align-middle me-2"
                data-v-d1a7e442=""
              ></i>
              <span class="align-middle">{{ $trans("Logout") }}</span></a
            >
          </div>
        </div>
      </div>
    </div>
  </header>
</template>
<script>
import { setLanguage, getCountry } from "@/lang/index";
import { languages } from "@/lang/languages";
export default {
  name: "AppHeader",
  data: () => ({
    companies: [],
    mode: "light",
    lay: "light",
    ActiveClass: "uil-moon",
    body: document.querySelector("body"),
    hasReadAll: false,
    languages,
    country: {},
  }),
  computed: {
    user() {
      return this.$store.state.user || {};
    },
    notifications() {
      return this.user.alerts || [];
    },
    hasNotifications() {
      return (
        this.notifications.some((item) => item.read_at == null) &&
        !this.hasReadAll
      );
    },
  },
  mounted() {
    this.country = getCountry();
    if (!this.user.company_id && !this.user.role_id) {
      this.$http.get("companies").then((response) => {
        this.companies = response.data.rows.data;
        this.$store.state.companies = this.companies;
      });
    }
  },
  methods: {
    setLanguage,
    handleLogout() {
      this.$http("auth/logout").then(() => {
        this.$auth.logout();
      });
    },
    setCompany(company) {
      if (company) {
        localStorage.setItem("_company", JSON.stringify(company));
        location.reload();
      } else {
        if (this.$store.state.selectedCompany) {
          localStorage.removeItem("_company");
          location.reload();
        }
      }
    },
    SwitchMode() {
      if (this.mode === "dark") {
        this.mode = "light";
        this.ActiveClass = "uil-moon";
        this.body.setAttribute("data-sidebar", this.mode);
        this.body.setAttribute("data-topbar", this.mode);
        this.body.setAttribute("data-layout-mode", this.mode);
      } else {
        this.mode = "dark";
        this.ActiveClass = "uil-sun";
        this.body.setAttribute("data-sidebar", this.mode);
        this.body.setAttribute("data-topbar", this.mode);
        this.body.setAttribute("data-layout-mode", this.mode);
      }
    },
    SwitchLayout() {
      if (this.lay === "dark") {
        this.lay = "light";
        this.size = "lg";
        this.body.setAttribute("data-sidebar-size", this.size);
      } else {
        this.lay = "dark";
        this.size = "sm";
        this.body.setAttribute("data-sidebar-size", this.size);
      }
    },
    handleSideBar() {
      this.body.classList.toggle("sidebar-enable");
    },
    readNotifications() {
      if (!this.hasReadAll) {
        this.$store.commit("SET_AJAX_TASK", "READING_NOTIFICATIONS");
        this.$http.get("auth/notifications/read").then(() => {
          this.hasReadAll = true;
        });
      }
    },
    readSingleNoti(id) {
      this.$store.commit("SET_AJAX_TASK", "READING_SINGLE_NOTIFICATION");
      this.$http.get(`auth/notifications/read/${id}`).then((response) => {
        if (response) {
          return response.data.success;
        }
      });
    },
  },
};
</script>
<style>
.switch {
  transform: scale(1.5);
}
</style>
