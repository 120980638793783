export const encodeQuery = (url, data) => {
  let query = "";
  for (let d in data) {
    if (data[d] && url.indexOf(`?${d}`) < 0 && url.indexOf(`&${d}`) < 0)
      query += encodeURIComponent(d) + "=" + encodeURIComponent(data[d]) + "&";
  }
  return url.indexOf("?") > -1
    ? `${url}&${query.slice(0, -1)}`
    : `${url}?${query.slice(0, -1)}`;
};

export const baseURL =
  process.env.NODE_ENV == "production" ? "/api/" : "https://store.nova.bi/api/";

export const getUploadedImage = (url) => {
  return process.env.NODE_ENV == "production"
    ? "/api/storage/" + url
    : "https://store.nova.bi/storage/" + url;
};