<template>
  <div id="layout-wrapper" v-if="$auth.active()" class="dark">
    <TopHeader></TopHeader>
    <app-header></app-header>
    <navigation-bar></navigation-bar>
    <AppFooter></AppFooter>
    <div class="main-content">
      <div class="page-content" style="margin-top: 40px">
        <div class="container-fluid">
          <div id="preloader" :style="`${!isFetching ? 'display: none;' : ''}`">
            <div
              data-loader="circle-side"
              :style="`${!isFetching ? 'display: none;' : ''}`"
            ></div>
          </div>
          <router-view />
        </div>
      </div>
    </div>
  </div>
  <router-view v-else />
  <NotificationAlert />
</template>
<script>
import AppHeader from "./components/AppHeader.vue";
import TopHeader from "./components/TopHeader.vue";
import NavigationBar from "./components/NavigationBar.vue";
import NotificationAlert from "./components/NotificationAlert.vue";
import AppFooter from "./components/footer.vue";
export default {
  name: "App",
  components: {
    AppHeader,
    NavigationBar,
    NotificationAlert,
    AppFooter,
    TopHeader,
  },
  data: () => ({}),
  computed: {
    isFetching() {
      return (
        this.$store.state.isLoading && this.$store.state.loadingTask == null
      );
    },
  },
  created() {
    if (this.$auth.active()) {
      this.$store.commit("SET_AJAX_TASK", "GETTING_CURRENT_USER");
      this.$http
        .get("auth/me")
        .then((response) => {
          this.$store.commit("SET_CURRENT_USER", response.data.user);
        })
        .catch((e) => console.log(e));
    }
  },
};
</script>
<style>
.loader-container {
  position: fixed;
  z-index: 999;
  height: 2em;
  width: 2em;
  overflow: show;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}
</style>
